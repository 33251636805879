import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { pageTypeConstants } from '../../../constants/pageTypeConstants';

// components
import { SbSharedRefineMenuInputRangeCurrency } from '../../SbSharedRefineMenu/SbSharedRefineMenuInputRange/SbSharedRefineMenuInputRangeCurrency';
import { SbSharedRefineMenuPriceRanges } from '../../SbSharedRefineMenu/SbSharedRefineMenuPriceRanges/SbSharedRefineMenuPriceRanges';
import { SbSharedRefineMenuSaleInput } from '../../SbSharedRefineMenu/SbSharedRefineMenuSaleInput/SbSharedRefineMenuSaleInput';
import { SbSharedRefineMenuNoReserve } from '../../SbSharedRefineMenu/SbSharedRefineMenuNoReserve/SbSharedRefineMenuNoReserve';
import { SbSharedRefineMenuNetPriceTradeDiscount } from '../../SbSharedRefineMenu/SbSharedRefineMenuNetPriceTradeDiscount/SbSharedRefineMenuNetPriceTradeDiscount';

const { AUCTION, COLLECTION } = pageTypeConstants;

import { SbRespRefineMenuFilterPrice_user$key } from './__generated__/SbRespRefineMenuFilterPrice_user.graphql';
import { SbRespRefineMenuFilterPrice_itemSearch$key } from './__generated__/SbRespRefineMenuFilterPrice_itemSearch.graphql';

type Props = {
    currency: string;
    user: SbRespRefineMenuFilterPrice_user$key;
    itemSearch: SbRespRefineMenuFilterPrice_itemSearch$key;
};

export const SbRespRefineMenuFilterPrice: FC<Props> = props => {
    const { user: userRef, itemSearch: itemSearchRef } = props;

    const user = useFragment(
        graphql`
            fragment SbRespRefineMenuFilterPrice_user on User {
                ...SbSharedRefineMenuPriceRanges_user
            }
        `,
        userRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SbRespRefineMenuFilterPrice_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuInputRangeCurrency_itemSearch
                ...SbSharedRefineMenuPriceRanges_itemSearch
                ...SbSharedRefineMenuSaleInput_itemSearch
                ...SbSharedRefineMenuNoReserve_itemSearch
                ...SbSharedRefineMenuNetPriceTradeDiscount_itemSearch
                pageType
            }
        `,
        itemSearchRef
    );

    let pageSpecificFilters = <SbSharedRefineMenuSaleInput {...props} itemSearch={itemSearch} />;
    switch (itemSearch.pageType) {
        case AUCTION:
            pageSpecificFilters = (
                <SbSharedRefineMenuNoReserve {...props} itemSearch={itemSearch} />
            );
            break;
        case COLLECTION:
            pageSpecificFilters = (
                <>
                    <SbSharedRefineMenuSaleInput {...props} itemSearch={itemSearch} />
                    <SbSharedRefineMenuNoReserve {...props} itemSearch={itemSearch} />
                </>
            );
            break;
    }

    return (
        <>
            <SbSharedRefineMenuPriceRanges {...props} user={user} itemSearch={itemSearch} />
            <SbSharedRefineMenuInputRangeCurrency
                {...props}
                itemSearch={itemSearch}
                showInvalidRangeErrorMessage={false}
            />

            <SbSharedRefineMenuNetPriceTradeDiscount itemSearch={itemSearch} />
            {pageSpecificFilters}
        </>
    );
};
