import { trackEvent } from 'dibs-tracking';

export const locations = {
    SEARCH_BROWSE: 'SEARCH_BROWSE',
    SOLD_PDP: 'SOLD_PDP',
    UNLISTED_PDP: 'UNLISTED_PDP',
} as const;

export const followSourceLabels = {
    [locations.SOLD_PDP]: 'sold pdp',
    [locations.UNLISTED_PDP]: 'unlisted pdp',
} as const;

export const authLabels = {
    [locations.SEARCH_BROWSE]: 'save search - search/browse page',
    [locations.SOLD_PDP]: 'save search - sold pdp',
    [locations.UNLISTED_PDP]: 'save search - unlisted pdp',
} as const;

export function trackFollowSearchAdded({
    location,
}: {
    location: keyof typeof followSourceLabels;
}): void {
    trackEvent({
        category: 'search favoriting',
        action: 'search added',
        label: followSourceLabels[location],
    });
}

export function trackFollowSearchRemoved({
    location,
}: {
    location: keyof typeof followSourceLabels;
}): void {
    trackEvent({
        category: 'search favoriting',
        action: 'search removed',
        label: followSourceLabels[location],
    });
}
