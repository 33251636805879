import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl, defineMessages } from 'dibs-react-intl';
import { useDispatch } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';
import { getFilterValues, hasFilterApplied } from '../sbSharedRefineMenuHelpers';
import { SbSharedRefineMenuInputCheckbox } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';
import { RESERVE_PRICE } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

const messages = defineMessages({
    title: {
        id: 'sb.SbSharedRefineMenuNoReserve.title',
        defaultMessage: 'No Reserve',
    },
});

import { SbSharedRefineMenuNoReserve_itemSearch$key } from './__generated__/SbSharedRefineMenuNoReserve_itemSearch.graphql';

type Props = {
    itemSearch: SbSharedRefineMenuNoReserve_itemSearch$key;
};

export const SbSharedRefineMenuNoReserve: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuNoReserve_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        linkReference
                    }
                }
                filters {
                    name
                    values {
                        linkReference
                        linkable
                        displayName
                        urlLabel
                        count
                    }
                }
            }
        `,
        itemSearchRef
    );

    const intl = useIntl();
    const dispatch = useDispatch();

    const filters = (itemSearch?.filters || []).filter(filterNulls);
    const filterValues = getFilterValues(filters, RESERVE_PRICE) || [];

    const appliedFilters = (itemSearch?.appliedFilters || []).filter(filterNulls);
    const isFilterApplied = hasFilterApplied(appliedFilters, [RESERVE_PRICE]);

    const reservePriceFilter = filterValues[0];
    if (!reservePriceFilter) {
        return null;
    }

    return (
        <SbSharedRefineMenuInputCheckbox
            key={reservePriceFilter.urlLabel}
            value={reservePriceFilter.urlLabel || ''}
            linkReference={reservePriceFilter.linkReference}
            linkable={reservePriceFilter.linkable}
            checked={isFilterApplied}
            onChange={(checked, event) => {
                dispatch(
                    updateUriRef({
                        filterName: RESERVE_PRICE,
                        filterValue: reservePriceFilter,
                        isDeselected: !checked,
                        event,
                        // TS complains
                        ga: undefined,
                        uriRef: undefined,
                    })
                );
            }}
            text={intl.formatMessage(messages.title)}
            count={reservePriceFilter.count}
        />
    );
};
