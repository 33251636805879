import PropTypes from 'prop-types';
import { graphql, useFragment } from 'react-relay';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';

// components
import { SbSharedRefineMenuRingSize } from '../SbSharedRefineMenuRingSize/SbSharedRefineMenuRingSize';

export const SbSharedRefineMenuFilterRingSize = ({
    filters: filtersRef,
    itemSearch: itemSearchRef,
}) => {
    const filters = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterRingSize_filters on SearchBrowseFilter
            @relay(plural: true) {
                ...SbSharedRefineMenuRingSize_filters
            }
        `,
        filtersRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterRingSize_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRingSize_itemSearch
            }
        `,
        itemSearchRef
    );

    return (
        <CurrencyProvider
            render={({ currency }) => (
                <SbSharedRefineMenuRingSize
                    currency={currency}
                    filters={filters}
                    itemSearch={itemSearch}
                />
            )}
        />
    );
};

SbSharedRefineMenuFilterRingSize.propTypes = {
    filters: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
};
