/**
 * @generated SignedSource<<f291ad11f08f26ebefc9d86bf4434522>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterWaysToShop_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRadioSelectList_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterWaysToShop_itemSearch";
};
export type SbSharedRefineMenuFilterWaysToShop_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterWaysToShop_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterWaysToShop_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterWaysToShop_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuRadioSelectList_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "35cef96f4b8ac077bb98732af30301e1";

export default node;
