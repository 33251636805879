import get from 'lodash.get';
import { pageTypeConstants as pageTypes } from '../../../constants/pageTypeConstants';
import {
    FILTER_SEARCH,
    FILTER_REFINE_SEARCH,
} from '../../../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';

import {
    getCategory,
    getCategories,
    categorySortOrder,
    getLowestCategory,
    getCategoryCode,
    getCategoryUrlLabel,
    getPageLevel,
    isCategory,
    CATEGORY_LEVEL_1,
    getCapitalizedFirstLetter,
} from '../../../utils/categoryHelpers';
import { SortMap } from '../../../constants/sbConstants';

const paramsConfig = {
    seller: 'dealer',
    location: 'loc',
    period: 'per',
    item_type: 'item-type', // eslint-disable-line camelcase
    lead_time_bucket: 'production-time-frame', // eslint-disable-line camelcase
    matchingSets: 'matching-sets',
};

const getAppliedCategoriesDisplayNames = (categories, topCategoryL1) => {
    return categorySortOrder
        .map(categoryName => {
            const category =
                categoryName === CATEGORY_LEVEL_1
                    ? topCategoryL1
                    : getCategoryUrlLabel(getCategory(categories, categoryName));
            return getCapitalizedFirstLetter(category);
        })
        .filter(Boolean);
};

const getPageSortLabel = sortValue => {
    return SortMap[sortValue];
};

const getFilterUrlLabel = (appliedFilters, filterName) => {
    const currentFilter = appliedFilters.filter(({ name }) => name === filterName);
    return get(currentFilter, '[0].values[0].urlLabel');
};

const getPageSubType = (pageType, searchCorrectionTerm) => {
    const map = {
        origin: 'Place of Origin',
        dealer: 'Dealers',
        auction: 'Auctions',
    };
    let pageSubType;

    if (searchCorrectionTerm && pageType !== pageTypes.SEARCH) {
        pageSubType = 'Semantic';
    } else if (pageType) {
        pageSubType = map[pageType] || getCapitalizedFirstLetter(pageType);
    }

    return pageSubType;
};

const getPageviewData = ({
    pageType,
    searchCorrectionTerm,
    appliedFilters = [],
    totalResults,
    pageNumber,
    optPageURL,
    sortValue,
    isModule,
    refineMenuFiltersMap,
    topCategoryL1,
    searchType,
}) => {
    const categories = getCategories(appliedFilters);
    const lowestCategory = getLowestCategory(categories);
    const appliedCategoriesDisplayNames = getAppliedCategoriesDisplayNames(
        categories,
        topCategoryL1
    );

    const isAuctionPage = pageType === pageTypes.AUCTION;
    // no pageLevel returned for auctions landing page
    const pageLevel = isAuctionPage && !lowestCategory ? 'L1' : getPageLevel(lowestCategory);
    const categoryCode = getCategoryCode(lowestCategory);

    // For L1s GA just wants the vertical label not the whole SEO name
    // i.e 'Jewelry' instead of 'Jewelry & Watches'
    // and 'Furniture' instead of '18th Century Furniture'
    const pageCategory = getCapitalizedFirstLetter(topCategoryL1);

    const pageSection = categories.map(getCategoryUrlLabel).join('/');
    const pageSort = getPageSortLabel(sortValue);
    const searchTerm =
        getFilterUrlLabel(appliedFilters, FILTER_SEARCH) ||
        getFilterUrlLabel(appliedFilters, FILTER_REFINE_SEARCH) ||
        '';
    const section = getFilterUrlLabel(appliedFilters, pageTypes.SECTION) || '';
    const pageSubType = getPageSubType(pageType, searchCorrectionTerm);
    const contentModule = isModule ? 'y' : 'n';

    if (pageType === pageTypes.BUY) {
        return {
            pageType: 'Results',
            pageSubType,
            pageSection,
        };
    }

    // object of all non-category applied filters keyed by name
    // ex. { color: ['red', 'silver'] }
    const appliedFilterSearchData = appliedFilters
        .filter(
            f => !isCategory(f) && !!refineMenuFiltersMap[f.name] && f.name !== FILTER_REFINE_SEARCH
        )
        .reduce((data, filter) => {
            const valueDisplayNames = filter.values.map(value =>
                getCapitalizedFirstLetter(value.urlLabel)
            );
            const filerName = paramsConfig[filter.name] || filter.name;
            data[filerName] = valueDisplayNames;

            return data;
        }, {});

    let appliedOptPageURL = optPageURL ? `${optPageURL}` : undefined;
    const searchData = {
        // eslint-disable-next-line camelcase
        applied_filters: appliedFilterSearchData,
        categories: appliedCategoriesDisplayNames,
        // eslint-disable-next-line camelcase
        search_term: searchCorrectionTerm || searchTerm,
        section,
    };

    if (searchCorrectionTerm && pageType !== pageTypes.SEARCH) {
        const splits = (optPageURL || '').split('?');
        const segments = get(splits, '[0]');
        const params = new URLSearchParams(get(splits, '[1]') || '');
        params.set('oq', searchCorrectionTerm);
        params.sort();
        const paramsString = decodeURIComponent(params.toString());

        appliedOptPageURL = `/semantic${segments}?${paramsString}`;
    }

    return {
        pageType: 'Results',
        pageSubType,
        categoryCode,
        pageCategory,
        pageLevel,
        pageSection,
        countOfItems: totalResults,
        contentModule,
        pageNumberViewed: pageNumber,
        pageSort,
        // eslint-disable-next-line camelcase
        opt_pageURL: appliedOptPageURL,
        viewMode: 'grid',
        searchData,
        search_type: searchType,
    };
};

export { getPageviewData };
