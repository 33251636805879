import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { SbSharedRefineMenuRadioSelectList } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectList';

import type { SbSharedRefineMenuFilterWaysToShop_itemSearch$data } from './__generated__/SbSharedRefineMenuFilterWaysToShop_itemSearch.graphql';

type Props = {
    itemSearch: SbSharedRefineMenuFilterWaysToShop_itemSearch$data;
};

export const SbSharedRefineMenuFilterWaysToShop: FC<Props> = ({
    itemSearch: itemSearchRef,
    ...props
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterWaysToShop_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectList_itemSearch
            }
        `,
        itemSearchRef
    );

    return <SbSharedRefineMenuRadioSelectList {...props} itemSearch={itemSearch} showAllOption />;
};
