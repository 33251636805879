import { trackEvent } from 'dibs-tracking';

type Args = {
    isFollowing: boolean;
    label: string;
    noninteraction?: number;
};

const trackFollowButtonClick = ({ isFollowing, label, noninteraction }: Args): void => {
    const action = isFollowing ? 'added' : 'removed';

    trackEvent({
        category: 'search favoriting',
        action: `search ${action}`,
        label,
        value: 0,
        noninteraction,
    });
};

export { trackFollowButtonClick };
