import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { updateUriRef } from '../../../actions/filterActions';
import { useRingMeasurementType } from '../../hooks/ringSize/useRingMeasurementType';
import { RING_SIZE_FILTER_NAME, RING_SIZE_US } from '../../../constants/ringSize';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { SbSharedRefineMenuRingSizeUnitSelector } from './SbSharedRefineMenuRingSizeUnitSelector';
import styles from './SbSharedRefineMenuRingSize.scss';

const COLLAPSED_RING_SIZE_COUNT = 20;

export const SbSharedRefineMenuRingSize = ({
    currency,
    filters: filtersRef,
    itemSearch: itemSearchRef,
}) => {
    const filters = useFragment(
        graphql`
            fragment SbSharedRefineMenuRingSize_filters on SearchBrowseFilter @relay(plural: true) {
                name
                totalCount
                localizedFilterName
                values {
                    displayName
                    urlLabel
                    linkReference
                    hexCode
                    properties {
                        mm
                        us
                    }
                }
            }
        `,
        filtersRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuRingSize_itemSearch on ItemSearchQueryConnection {
                ...useRingMeasurementType_itemSearch
                ...SbSharedRefineMenuRingSizeUnitSelector_itemSearch
                appliedFilters {
                    name
                    values {
                        urlLabel
                        displayName
                        linkReference
                        properties {
                            mm
                            us
                        }
                    }
                }
            }
        `,
        itemSearchRef
    );

    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const [ringMeasurementType] = useRingMeasurementType({ currency, itemSearch });
    const ringSizeFilters = getFilterValues(filters, RING_SIZE_FILTER_NAME);
    const appliedRingSizeFilters = getFilterValues(
        itemSearch?.appliedFilters,
        RING_SIZE_FILTER_NAME
    );
    const selectedRingSizes = {};
    (appliedRingSizeFilters || []).forEach(filter => {
        selectedRingSizes[filter.urlLabel] = true;
    });

    const onClickRingSize = (filterValue, isSelected, event) => {
        dispatch(
            updateUriRef({
                filterName: RING_SIZE_FILTER_NAME,
                filterValue,
                isDeselected: !isSelected,
                event,
            })
        );
    };

    return (
        <>
            <SbSharedRefineMenuRingSizeUnitSelector currency={currency} itemSearch={itemSearch} />
            <div className={styles.ringSizeFilterContainer}>
                {ringSizeFilters.map((filter, index) => {
                    const isSelected = selectedRingSizes[filter.urlLabel];
                    return isExpanded || index < COLLAPSED_RING_SIZE_COUNT ? (
                        <div
                            className={`${styles.ringSizeFilterTile} ${
                                isSelected ? styles.selected : ''
                            }`}
                            onClick={e => {
                                onClickRingSize(filter, isSelected, e);
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onClickRingSize(filter, isSelected, e);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {ringMeasurementType === RING_SIZE_US
                                ? filter?.properties?.us
                                : filter?.properties?.mm}
                        </div>
                    ) : null;
                })}
            </div>
            <Link
                buyerLinkType="standardLink"
                className={styles.expandFilters}
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
                ariaExpanded={isExpanded}
                dataTn={`see-${isExpanded ? 'less' : 'more'}`}
            >
                <FormattedMessage
                    id="sb.SbSharedRefineMenuRingSize.seeMore"
                    defaultMessage={`{isExpanded, select,
                                true {See Less}
                                other {See More}
                            }`}
                    values={{ isExpanded }}
                />
            </Link>
        </>
    );
};

SbSharedRefineMenuRingSize.propTypes = {
    currency: PropTypes.string,
    filters: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
};
