import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { getAppliedFilter, getFilterValues } from '../sbSharedRefineMenuHelpers';

// Constants
import { SbSharedRefineMenuRadioSelect } from '../../SbSharedRefineMenu/SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelect';

// Constants
import { NET_PRICE_TRADE_DISCOUNT } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

import { SbSharedRefineMenuNetPriceTradeDiscount_itemSearch$key } from './__generated__/SbSharedRefineMenuNetPriceTradeDiscount_itemSearch.graphql';

import styles from './SbSharedRefineMenuNetPriceTradeDiscount.scss';
import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';

type Props = {
    itemSearch: SbSharedRefineMenuNetPriceTradeDiscount_itemSearch$key;
};

export const SbSharedRefineMenuNetPriceTradeDiscount: FC<Props> = ({
    itemSearch: itemSearchRef,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuNetPriceTradeDiscount_itemSearch on ItemSearchQueryConnection {
                displayUriRef
                filters {
                    name
                    localizedFilterName
                    values {
                        displayName
                        urlLabel
                        count
                        linkReference
                        linkable
                    }
                }
                appliedFilters {
                    name
                    values {
                        linkReference
                        urlLabel
                    }
                }
            }
        `,
        itemSearchRef
    );
    const { isNewNetPrice15Filter } = useServerVarsContext();
    const { displayUriRef } = itemSearch;
    const filters = (itemSearch?.filters || []).filter(filterNulls);
    const appliedFilters = (itemSearch?.appliedFilters || []).filter(filterNulls);

    const valuesToShow = getFilterValues(filters, NET_PRICE_TRADE_DISCOUNT);
    if (valuesToShow.length === 0 || isNewNetPrice15Filter) {
        return null;
    }
    const localizedFilterName = getAppliedFilter(
        filters,
        NET_PRICE_TRADE_DISCOUNT
    )?.localizedFilterName;
    const appliedFilterValues = [...getFilterValues(appliedFilters, NET_PRICE_TRADE_DISCOUNT)];

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{localizedFilterName}</div>
            <SbSharedRefineMenuRadioSelect
                filterName={NET_PRICE_TRADE_DISCOUNT}
                valuesToShow={valuesToShow}
                appliedFilterValues={appliedFilterValues}
                displayUriRef={displayUriRef}
                canBeDismissed
                trackInputIndex
            />
        </div>
    );
};
